@import "../fonts/sarabun.css";
@import "../fonts/caveat.css";

body {
  background-color: var(--background-color);
}

h1 {
  font-family: "Sarabun";
  color: var(--font-color);
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0%;
  padding-bottom: 0%;
  font-weight: 800;
  font-size: 6.5vw; /*was 100px */
  margin-bottom: 0;
}

h2 {
  font-family: "Sarabun";
  color: var(--subtitle);
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0%;
  padding-bottom: 0%;
  font-weight: 600;
  font-size: 3.25vw;
  margin-top: 0;
}

p {
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  font-family: "Sarabun";
  color: var(--font-color);
  font-size: 2.08vw;
  font-weight: normal;
}

button {
  font-family: "Sarabun";
  font-weight: medium;
  font-size: 2.08vw;
  color: var(--font-color);
  background-color: var(--background-color);
  outline: 1px solid var(--font-color);
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
  width: 25vw;
  border-radius: 1vw;
  cursor: pointer;
  z-index: 999999;
  transition-property: background-color, color;
  transition-duration: 500ms;
  pointer-events: visible;
}

* {
    transition-property: background-color,
    color, stroke, fill;
    transition-duration: 500ms;
}

button:hover {
  color: var(--font-color);
  background-color: var(--background-color);
  outline: 1px solid var(--font-color)
}

b {
  color: var(--font-color);
  font-weight: 600px;
}

#semi-circle {
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 13vw;
  height: 6.5vw;
  position: absolute;
  border-radius: 150px 150px 0% 0%;
  background-color: #535182;
  border: 4px solid #535182;
  outline: none;
  cursor: pointer;
}

em {
  color :var(--accent);
  font-style: normal;
}

@keyframes MoveUpDown {

  0%,
  100% {
    transform: translateY(+1vh);
  }

  50% {
    transform: translateY(-1vh);
  }
}

h1.title {
      width: auto;
      margin-top: 6vh;
}

a {
  color: var(--font-color);
  font-family: "Sarabun";
  font-weight: normal;
  font-size: 1.7vw;
  text-decoration: none;
  margin-left: 0.1%;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding: auto;
  pointer-events: auto;
}

a:focus {
  outline: 1px var(--font-color) dashed;
}

h4 {
  font-family: 'Caveat';
  font-weight: normal;
  color: var(--font-color);
  text-shadow: 0px -1% 1% rgba(255, 255, 255);
  font-size: 2vw;
}

h3 {
  text-align: center;
  color: var(--font-color);
  font-family: 'Sarabun';
}