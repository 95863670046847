.Portfolio {
    background: var(--background-color);
    min-height: 100vh;
    height: auto;
    width: 100vw;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0px;
    padding: 0px;
    z-index: 0;
    overflow-y: scroll;
}

div.background {
    background-image: url("../images/background.svg");
    width: 100vw;
    height: 93%;
    top: 10vw;
    z-index: -20;
    background-repeat: repeat-y;
    position:absolute;
}

.filters {
    width: 35vw;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    margin-top: 2vw;
    margin-bottom: 2vw;
    padding: auto;
    display: flex;
}

button.filter {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
    padding-left: 2vw;
    padding-right: 2vw;
    width: auto;
    border-radius: 500px;
    display: block;
    height: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
}

button.filter_clicked {
    display: flex;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 0;
    padding-right: 2vw;
    width: auto;
    height: auto;
    border-radius: 500px;
    height: 4vw;
    align-items: center;
    background-color: var(--font-color);
    color: var(--background-color);
}

.projects {
    width: 80vw;
    height: auto;
    margin: auto;
    padding: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 2;
}



@media only screen and (max-width: 786px) {
    .projects {
        width: 80vw;
        height: auto;
        margin: auto;
        padding: auto;
        display: grid;
        grid-template-columns: 1fr;
    }
}

svg.check {
    height: 1.5vw;
    object-fit: contain;
    margin-top: auto;
    margin-bottom: auto;
    padding: auto;
    stroke: var(--font-color)
}

svg.check_clicked {
    height: 1.5vw;
    object-fit: contain;
    margin-top: auto;
    margin-bottom: auto;
    padding: auto;
    stroke: var(--background-color)
}

button.filter_clicked {
    display: flex;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 0;
    padding-right: 2vw;
    width: auto;
    height: auto;
    border-radius: 500px;
    height: 4vw;
    align-items: center
}

div.text {
    white-space: nowrap;
    overflow: hidden;
    display: flex;
}

svg.uparrow {
    fill: var(--font-color);
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vw;
    margin-bottom: auto;
    display: flex;
    width: 5vw;
    height: 5vw;
    animation: MoveUpDown 3s ease-in-out infinite;
}