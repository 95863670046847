body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --font-color: #222135;
  --background-color: white;
  --accent: #EE5F5F;
  --subtitle: #6F6F6F;
  --icons: #CFCFCF;
}

[data-theme="dark"] {
  --font-color: white;
  --background-color: #222135;
  --accent: #EE5F5F;
  --subtitle: #CFCFCF;
  --icons: #6F6F6F;
}
@font-face {
    font-family: "Sarabun";
    src: url(/static/media/Sarabun-ExtraBold.c6db3de5.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Sarabun";
    src: url(/static/media/Sarabun-Regular.56c5f9d4.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Sarabun";
    src: url(/static/media/Sarabun-Bold.6173018c.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Sarabun";
    src: url(/static/media/Sarabun-Medium.c0ee849b.ttf) format('truetype');
    font-weight: medium;
    font-style: normal;
}

@font-face {
    font-family: "Sarabun";
    src: url(/static/media/Sarabun-SemiBold.9cae766f.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Sarabun";
    src: url(/static/media/Sarabun-Light.5860e622.ttf) format('truetype');
    font-weight: light;
    font-style: normal;
}
@font-face {
    font-family: "Caveat";
    src: url(/static/media/Caveat-Regular.04c3547e.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Caveat";
    src: url(/static/media/Caveat-Bold.5572b752.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Caveat";
    src: url(/static/media/Caveat-Medium.b720a047.ttf) format('truetype');
    font-weight: medium;
    font-style: normal;
}

@font-face {
    font-family: "Caveat";
    src: url(/static/media/Caveat-SemiBold.ec80dc3f.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}
body {
  background-color: var(--background-color);
}

h1 {
  font-family: "Sarabun";
  color: var(--font-color);
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0%;
  padding-bottom: 0%;
  font-weight: 800;
  font-size: 6.5vw; /*was 100px */
  margin-bottom: 0;
}

h2 {
  font-family: "Sarabun";
  color: var(--subtitle);
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0%;
  padding-bottom: 0%;
  font-weight: 600;
  font-size: 3.25vw;
  margin-top: 0;
}

p {
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  font-family: "Sarabun";
  color: var(--font-color);
  font-size: 2.08vw;
  font-weight: normal;
}

button {
  font-family: "Sarabun";
  font-weight: medium;
  font-size: 2.08vw;
  color: var(--font-color);
  background-color: var(--background-color);
  outline: 1px solid var(--font-color);
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
  width: 25vw;
  border-radius: 1vw;
  cursor: pointer;
  z-index: 999999;
  transition-property: background-color, color;
  transition-duration: 500ms;
  pointer-events: visible;
}

* {
    transition-property: background-color,
    color, stroke, fill;
    transition-duration: 500ms;
}

button:hover {
  color: var(--font-color);
  background-color: var(--background-color);
  outline: 1px solid var(--font-color)
}

b {
  color: var(--font-color);
  font-weight: 600px;
}

#semi-circle {
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 13vw;
  height: 6.5vw;
  position: absolute;
  border-radius: 150px 150px 0% 0%;
  background-color: #535182;
  border: 4px solid #535182;
  outline: none;
  cursor: pointer;
}

em {
  color :var(--accent);
  font-style: normal;
}

@-webkit-keyframes MoveUpDown {

  0%,
  100% {
    -webkit-transform: translateY(+1vh);
            transform: translateY(+1vh);
  }

  50% {
    -webkit-transform: translateY(-1vh);
            transform: translateY(-1vh);
  }
}

@keyframes MoveUpDown {

  0%,
  100% {
    -webkit-transform: translateY(+1vh);
            transform: translateY(+1vh);
  }

  50% {
    -webkit-transform: translateY(-1vh);
            transform: translateY(-1vh);
  }
}

h1.title {
      width: auto;
      margin-top: 6vh;
}

a {
  color: var(--font-color);
  font-family: "Sarabun";
  font-weight: normal;
  font-size: 1.7vw;
  text-decoration: none;
  margin-left: 0.1%;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding: auto;
  pointer-events: auto;
}

a:focus {
  outline: 1px var(--font-color) dashed;
}

h4 {
  font-family: 'Caveat';
  font-weight: normal;
  color: var(--font-color);
  text-shadow: 0px -1% 1% rgba(255, 255, 255);
  font-size: 2vw;
}

h3 {
  text-align: center;
  color: var(--font-color);
  font-family: 'Sarabun';
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

.Home {
    background: var(--background-color);
    min-height: 100vh;
    width: 100vw;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0px;
    padding: 0px;
    z-index: 1;
}

.about {
    width: 100%;
    margin-top: 6vh;
    pointer-events: none;
}


#name {
    width: 60vw;
}

#title {
    width: auto;
    display: inline-flex;
}

#bio {
    width: 70vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    -webkit-user-select: text;
            user-select: text;
}


img, svg {
    margin: 5%;
    margin-right: 1%;
    margin-top: auto;
    margin-bottom: auto;
    width: 4vw;
    height: 4vw;
    vertical-align: middle;
}

svg.contacts {
    stroke: var(--font-color);
}

.info {
    margin-top: 6%;
}

#ResumeArrow {
    position: absolute;
    transition: none;
    right: 2%;
    top: 7.8%;
    left: 75%;
    bottom: 75%;
    display: inline-block;
}

img.ResumeArrow:hover {
    object-fit: cover;
    width: 100%;
    height: 250px;
    transition: none;
    right: 0px;
    top: 0px;
    position: absolute;
}
.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: var(--font-color);
    opacity: 0.2;
    -webkit-animation: circ_animate 25s linear infinite;
            animation: circ_animate 25s linear infinite;
    bottom: -150px;
    transition-property: background-color;
    transition-duration: 500ms;
}

.circles li:hover {
    background-color: var(--accent);
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 18s;
            animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    -webkit-animation-delay: 15s;
            animation-delay: 15s;
    -webkit-animation-duration: 45s;
            animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 35s;
            animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 11s;
            animation-duration: 11s;
}



@-webkit-keyframes circ_animate {

    0% {
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}



@keyframes circ_animate {

    0% {
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
.links {
    color: white;
    width: 30vw;
    height: 10vh;
    right: 0px;
    left: auto;
    position: absolute;
    display: inline-flex;
}

.name {
    color: var(--font-color);
    width: 20vw;
    height: 10vh;
    left: 0px;
    right: auto;
    position: absolute;
    display: inline-flex;
    margin-left: 5vw;
}

svg.logo {
    height: 1.7vw;
    width: auto;
    margin-right: 0.5vw;
    fill: var(--font-color);
}

svg {
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: middle;

}

a {
    color: var(--font-color);
    font-family: "Sarabun";
    font-weight: normal;
    font-size: 1.7vw;
    text-decoration: none;
    margin-left: 0.1%;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding: auto;
}

a:hover {
    font-weight: 600;
}

a.selected {
    color: var(--accent);
    font-weight: 600;
}
.Portfolio {
    background: var(--background-color);
    min-height: 100vh;
    height: auto;
    width: 100vw;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0px;
    padding: 0px;
    z-index: 0;
    overflow-y: scroll;
}

div.background {
    background-image: url(/static/media/background.8748b053.svg);
    width: 100vw;
    height: 93%;
    top: 10vw;
    z-index: -20;
    background-repeat: repeat-y;
    position:absolute;
}

.filters {
    width: 35vw;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    margin-top: 2vw;
    margin-bottom: 2vw;
    padding: auto;
    display: flex;
}

button.filter {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
    padding-left: 2vw;
    padding-right: 2vw;
    width: auto;
    border-radius: 500px;
    display: block;
    height: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 300ms linear;
}

button.filter_clicked {
    display: flex;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 0;
    padding-right: 2vw;
    width: auto;
    height: auto;
    border-radius: 500px;
    height: 4vw;
    align-items: center;
    background-color: var(--font-color);
    color: var(--background-color);
}

.projects {
    width: 80vw;
    height: auto;
    margin: auto;
    padding: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 2;
}



@media only screen and (max-width: 786px) {
    .projects {
        width: 80vw;
        height: auto;
        margin: auto;
        padding: auto;
        display: grid;
        grid-template-columns: 1fr;
    }
}

svg.check {
    height: 1.5vw;
    object-fit: contain;
    margin-top: auto;
    margin-bottom: auto;
    padding: auto;
    stroke: var(--font-color)
}

svg.check_clicked {
    height: 1.5vw;
    object-fit: contain;
    margin-top: auto;
    margin-bottom: auto;
    padding: auto;
    stroke: var(--background-color)
}

button.filter_clicked {
    display: flex;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 0;
    padding-right: 2vw;
    width: auto;
    height: auto;
    border-radius: 500px;
    height: 4vw;
    align-items: center
}

div.text {
    white-space: nowrap;
    overflow: hidden;
    display: flex;
}

svg.uparrow {
    fill: var(--font-color);
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vw;
    margin-bottom: auto;
    display: flex;
    width: 5vw;
    height: 5vw;
    -webkit-animation: MoveUpDown 3s ease-in-out infinite;
            animation: MoveUpDown 3s ease-in-out infinite;
}
a.project {
    margin-top: 0.5vw;
    margin-bottom: 1vw;
    background-color: none;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

div.project {
    width: 38vw;
    height: 23vw;
    margin: auto;
    position: relative;
    cursor: pointer;
    background-color: var(--font-color);
    border-radius: 5px;
    z-index: -2;
}

a.project:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
}

img.project {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
}

div.imageproject {
    width: 46%;
    height: 20vw;
    right: 2%;
    top: 0%;
    bottom: 0%;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    z-index: -1;
}

h5.projTitle {
    font-family: "Sarabun";
    font-weight: 600;
    font-size: 2.5vw;
    color: var(--background-color);
}

h6 {
    font-family: "Sarabun";
    font-weight: 600;
    font-size: 1vw;
    margin-left: .5vw;
    margin-top: 1vw;
    color: var(--icons);
}

p.description {
    font-family: "Sarabun";
    font-size: 1vw;
    padding-left: 0;
    margin: 0px;
    margin-top: -1.5vw;
    margin-left: 3.5vw;
    font-weight: 600;
    color: var(--icons);
}

div.information {
    margin: 1%;
    text-align: left;
    width: 100%;
    padding: 1%;
}

div.subinformation {
    width: 50%;
}

.text {
    vertical-align: middle;
    display: inline-block;
}

h6.texti {
    padding-left: 0;
    margin: 0px;
    margin-top: -1.5vw;
    margin-left: 3.5vw;
}

svg.icon {
    vertical-align: middle;
    display: inline-block;
    stroke: var(--icons);
    width: 1.5vw;
    height: 1.5vw;
    margin-top: 1vw;
    padding: 0;
}

svg.fill_icon {
    fill: var(--icons);
}

div.no_space {
    margin: 0;
    padding: 0;
}

div.tags {
    position: absolute;
    border-radius: 500px;
    color: var(--font-color);
    background-color: var(--background-color);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: -1vw;
    margin-top: -1vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    width: auto;
    border-radius: 500px;
    display: block;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1.5vw;
    top: 1.5vw;
}


p.tag {
    font-size: 1vw;
    font-weight: 600;
    white-space: nowrap;
    overflow: visible;
    display: flex;
}


@media only screen and (max-width: 786px) {
    div.project {
        width: 76vw;
        height: 43vw;
        margin: auto;
        position: relative;
        cursor: pointer;
        background-color: var(--font-color);
        border-radius: 5px;
        z-index: -2;
    }

    a.project:hover {
        -webkit-transform: translateY(-3px);
                transform: translateY(-3px);
    }

    img.project {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
    }

    div.imageproject {
        width: 46%;
        height: 40vw;
        right: 2%;
        top: 0%;
        bottom: 0%;
        margin-top: auto;
        margin-bottom: auto;
        position: absolute;
        z-index: -1;
    }

    h5.projTitle {
        font-family: "Sarabun";
        font-weight: 600;
        font-size: 5vw;
        color: var(--background-color);
    }

    h6 {
        font-family: "Sarabun";
        font-weight: 600;
        font-size: 2vw;
        margin-left: 1vw;
        color: var(--icons);
    }

    p.description {
        font-family: "Sarabun";
        font-size: 2vw;
        padding-left: 0;
        margin-top: -3vw;
        margin-left: 7vw;
        font-weight: 600;
        color: var(--icons);
    }

    div.information {
        margin: 1%;
        text-align: left;
        width: 100%;
        padding: 1%;
    }

    div.subinformation {
        width: 50%;
    }

    .text {
        vertical-align: middle;
        display: inline-block;
    }

    h6.texti {
        padding-left: 0;
        margin: 0px;
        margin-left: 7vw;
        margin-top: -3vw;
    }

    svg.icon {
        vertical-align: middle;
        display: inline-block;
        stroke: var(--icons);
        width: 3vw;
        height: 3vw;
        margin-top: 1vw;
        padding: 0;
    }

    div.tags {
        position: absolute;
        border-radius: 500px;
        color: var(--font-color);
        background-color: var(--background-color);
        margin-right: auto;
        margin-left: auto;
        margin-bottom: -1vw;
        margin-top: -1vw;
        padding-left: 1.5vw;
        padding-right: 1.5vw;
        width: auto;
        border-radius: 500px;
        display: block;
        height: 6vw;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 3vw;
        top: 3vw;
    }
    
    
    p.tag {
        font-size: 2vw;
        font-weight: 600;
        white-space: nowrap;
        overflow: visible;
        display: flex;
    }
}
.About {
    background: var(--background-color);
    height: 100vh;
    width: 100vw;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0px;
    padding: 0px;
    z-index: 1;
}

#rf0 {
    -webkit-transform: rotate(123.93deg);
            transform: rotate(123.93deg);
    left: -5%;
    top: -3%;
    -webkit-animation: walk 1s ease 0.6s;
            animation: walk 1s ease 0.6s;
}

#lf0 {
    -webkit-transform: rotate(144.57deg);
            transform: rotate(144.57deg);
    left: 6%;
    top: 0%;
    -webkit-animation: walk 1s ease 1s;
            animation: walk 1s ease 1s;
}

#rf1 {
    -webkit-transform: rotate(123.93deg);
            transform: rotate(123.93deg);
    left: 2%;
    top: 15%;
    -webkit-animation: walk 1s ease 1.4s;
            animation: walk 1s ease 1.4s;
}

#lf1 {
    -webkit-transform: rotate(144.57deg);
            transform: rotate(144.57deg);
    left: 15%;
    top: 16%;
    -webkit-animation: walk 1s ease 1.6s;
            animation: walk 1s ease 1.6s;
}

#rf2 {
    -webkit-transform: rotate(123.93deg);
            transform: rotate(123.93deg);
    left: 12%;
    top: 30%;
    -webkit-animation: walk 1s ease 2s;
            animation: walk 1s ease 2s;
}

#lf2 {
    -webkit-transform: rotate(134.57deg);
            transform: rotate(134.57deg);
    left: 23%;
    top: 29%;
    -webkit-animation: walk 1s ease 2.4s;
            animation: walk 1s ease 2.4s;
}


#rf3 {
    -webkit-transform: rotate(105.7deg);
            transform: rotate(105.7deg);
    left: 25%;
    top: 45%;
    -webkit-animation: walk 1s ease 2.8s;
            animation: walk 1s ease 2.8s;
}

#lf3 {
    -webkit-transform: rotate(97.93deg);
            transform: rotate(97.93deg);
    left: 34%;
    top: 35%;
    -webkit-animation: walk 1s ease 3.2s;
            animation: walk 1s ease 3.2s;
}

#rf4 {
    -webkit-transform: rotate(68.33deg);
            transform: rotate(68.33deg);
    left: 39%;
    top: 46%;
    -webkit-animation: walk 1s ease 3.6s;
            animation: walk 1s ease 3.6s;
}

#lf4 {
    -webkit-transform: rotate(77.06deg);
            transform: rotate(77.06deg);
    left: 46%;
    top: 31%;
    -webkit-animation: walk 1s ease 4s;
            animation: walk 1s ease 4s;
}

#rf5 {
    -webkit-transform: rotate(70.12deg);
            transform: rotate(70.12deg);
    left: 52%;
    top: 40%;
    -webkit-animation: walk 1s ease 4.4s;
            animation: walk 1s ease 4.4s;
}

#lf5 {
    -webkit-transform: rotate(97.4deg);
            transform: rotate(97.4deg);
    left: 59%;
    top: 28%;
    -webkit-animation: walk 1s ease 4.8s;
            animation: walk 1s ease 4.8s;
}

#rf6 {
    -webkit-transform: rotate(108.66deg);
            transform: rotate(108.66deg);
    left: 63%;
    top: 45%;
    -webkit-animation: walk 1s ease 5.2s;
            animation: walk 1s ease 5.2s;
}

#lf6 {
    -webkit-transform: rotate(131.03deg);
            transform: rotate(131.03deg);
    left: 71%;
    top: 35%;
    -webkit-animation: walk 1s ease 5.6s;
            animation: walk 1s ease 5.6s;
}

#rf7 {
    -webkit-transform: rotate(123.71deg);
            transform: rotate(123.71deg);
    left: 73%;
    top: 57%;
    -webkit-animation: walk 1s ease 6s;
            animation: walk 1s ease 6s;
}

#lf7 {
    -webkit-transform: rotate(155.17deg);
            transform: rotate(155.17deg);
    left: 82%;
    top: 54.5%;
    -webkit-animation: walk 1s ease 6.4s;
            animation: walk 1s ease 6.4s;
}

#rf8 {
    -webkit-transform: rotate(131.71deg);
            transform: rotate(131.71deg);
    left: 81%;
    top: 74%;
    -webkit-animation: walk 1s ease 6.8s;
            animation: walk 1s ease 6.8s;
}

#lf8 {
    -webkit-transform: rotate(137.17deg);
            transform: rotate(137.17deg);
    left: 90%;
    top: 73%;
    -webkit-animation: walk 1s ease 7.2s;
            animation: walk 1s ease 7.2s;
}

#rf9 {
    -webkit-transform: rotate(100.71deg);
            transform: rotate(100.71deg);
    left: 91%;
    top: 88%;
    -webkit-animation: walk 1s ease 7.6s;
            animation: walk 1s ease 7.6s;
}



img.footprints:hover {
    -webkit-filter: brightness(3);
            filter: brightness(3);
}

img.footprints {
    position: absolute;
    transition: -webkit-filter 1s ease;
    transition: filter 1s ease;
    transition: filter 1s ease, -webkit-filter 1s ease;
    height: min(8.5vw, 16vh);
    z-index: -1;
    pointer-events: all;

}

@-webkit-keyframes walk {
    0% {
        -webkit-filter: brightness(1);
                filter: brightness(1);
    }

    50% {
        -webkit-filter: brightness(3);
                filter: brightness(3);
    }

    100% {
        -webkit-filter: brightness(1);
                filter: brightness(1);
    }
}

@keyframes walk {
    0% {
        -webkit-filter: brightness(1);
                filter: brightness(1);
    }

    50% {
        -webkit-filter: brightness(3);
                filter: brightness(3);
    }

    100% {
        -webkit-filter: brightness(1);
                filter: brightness(1);
    }
}

svg.fill {
    fill: var(--font-color);
}

svg.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 5vw;
    height: 5vw;
}

svg.stroke {
    stroke: var(--font-color);
}

svg.strokeish {
    stroke: var(--font-color);
}

div.family {
    left: 9vw;
    top: 24vh;
}

div.education {
    left: 30vw;
    top: 38vh;
}

div.work {
    left: 52vw;
    top: 26vh;
}

div.fun {
    left: 74vw;
    top: 40vh;
}

div.aboutme {
    position: absolute;
    width: 20vw;
    height: auto;
    pointer-events: none;
}

p.about {
    text-align: center;
    margin: 0;
    width: 100%;
    padding: 0;
    font-size: 1.5vw;
    font-weight: 600;
}

@media only screen and (max-width: 786px) {
    div.aboutme {
        position: relative;
        width: 100%;
        height: auto;
        pointer-events: none;
        top: auto;
        left: auto;
        display: block;
        margin: 0;
        margin-bottom: 3vw;
    }
    
    div.family {
        margin-top: 4vw;
    }

    svg.center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 10vw;
        height: 10vh;
    }

    p.about {
        text-align: center;
        margin: 0;
        width: 100%;
        padding: 10px;
        font-size: 2.6vw;
        font-weight: 600;
        padding: 0;
    }
}
.PageNotFound {
    background: var(--background-color);
    min-height: 100vh;
    width: 100vw;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0px;
    padding: 0px;
    z-index: 1;
}

.error {
    text-align: center;
    top:0;
    bottom:0;
    margin-top: 20vh;
}
.Programming {
    background: var(--background-color);
    height: auto;
    width: 100vw;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0px;
    padding: 0px;
}

img.main {
    margin-top: 10vh;
    max-width: 65vw;
    max-height: 65vw;
    height: auto;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

h1.main {
    text-align: center;
}

h1.divider {
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    display: block;
    font-size: 4vw;
    line-height: 8vw;
    color: white;
}

div.seperator {
    display: block;
    margin-top: 3vw;
    margin-bottom: 3vw;
    width: 100vw;
    height: 8vw;
    background-color: var(--accent);
}

div.overview {
    margin-top: 3vw;
    margin-bottom: 2vw;
    width: 80vw;
    height: 27vw;
    display: grid;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr 0.66fr;
    grid-gap: 20px;
}

div.overview_info {
    width: auto;
    height: auto;
    grid-row: 1 / span 3;
}

p.overview {
    margin: 0;
    padding: 0;
}

p.title {
    margin-bottom: 1vw;
}

div.proj_links {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3vw;
    width: 80vw;
    text-align: center;
}

svg.stroke {
    stroke: var(--font-color);
    vertical-align: middle;
    display: inline-block;
}

div.section {
    margin-top: 3vw;
    margin-bottom: 2vw;
    width: 80vw;
    height: auto;
    display: grid;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 0.5fr 1fr;
    grid-gap: 20px;
}

div.titles {
    text-align: right;
}

div.descriptor {
    text-align: left;
}

p.align {
    text-align: left;
    padding-left: 0.75vw;
}

p.leftish {
    text-align: left;
    padding-left: 3vw;
    color: var(--subtitle)
}

p.suboverview {
    margin: 0;
    padding: 0;
    font-size: 1.25vw;
    color: var(--subtitle);
}

p.descriptor {
    margin: 0;
    padding: 0;
}

p.bar {
    content: "";
    display: block;
    z-index: 1;
    left: -200px;
    top: 0;
    bottom: 0;
    border: 0.5vw solid var(--font-color);
    border-width: 0 0 0 0.5vw;
}

figcaption {
    margin: 0;
    padding: 0;
    font-size: 1.25vw;
    color: var(--subtitle);
    font-family: "Sarabun";
    color: var(--subtitle);
    font-weight: 600;
    text-align: center;

}

img.centerImage {
    max-width: 75vw;
    max-height: 60vh;
    object-fit: contain;
    width: 100%;
    height: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vw;
}

img.full {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    width: 100%;
    height: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

p.caption {
    text-align: right;
    font-weight: 600;
    font-size: 1.25vw;
}

div.caption {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
}

div.captionish {
    align-items: left;
    justify-content: left;
    display: flex;
    width: 100%;
    height: 100%;
}

div.center {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    margin-top: 3vw;
    margin-bottom: 3vw;
}
