@import "../fonts/sarabun.css";

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

.Home {
    background: var(--background-color);
    min-height: 100vh;
    width: 100vw;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0px;
    padding: 0px;
    z-index: 1;
}

.about {
    width: 100%;
    margin-top: 6vh;
    pointer-events: none;
}


#name {
    width: 60vw;
}

#title {
    width: auto;
    display: inline-flex;
}

#bio {
    width: 70vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    user-select: text;
}


img, svg {
    margin: 5%;
    margin-right: 1%;
    margin-top: auto;
    margin-bottom: auto;
    width: 4vw;
    height: 4vw;
    vertical-align: middle;
}

svg.contacts {
    stroke: var(--font-color);
}

.info {
    margin-top: 6%;
}

#ResumeArrow {
    position: absolute;
    transition: none;
    right: 2%;
    top: 7.8%;
    left: 75%;
    bottom: 75%;
    display: inline-block;
}

img.ResumeArrow:hover {
    object-fit: cover;
    width: 100%;
    height: 250px;
    transition: none;
    right: 0px;
    top: 0px;
    position: absolute;
}