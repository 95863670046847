@import "../fonts/sarabun.css";

a.project {
    margin-top: 0.5vw;
    margin-bottom: 1vw;
    background-color: none;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

div.project {
    width: 38vw;
    height: 23vw;
    margin: auto;
    position: relative;
    cursor: pointer;
    background-color: var(--font-color);
    border-radius: 5px;
    z-index: -2;
}

a.project:hover {
    transform: translateY(-3px);
}

img.project {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
}

div.imageproject {
    width: 46%;
    height: 20vw;
    right: 2%;
    top: 0%;
    bottom: 0%;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    z-index: -1;
}

h5.projTitle {
    font-family: "Sarabun";
    font-weight: 600;
    font-size: 2.5vw;
    color: var(--background-color);
}

h6 {
    font-family: "Sarabun";
    font-weight: 600;
    font-size: 1vw;
    margin-left: .5vw;
    margin-top: 1vw;
    color: var(--icons);
}

p.description {
    font-family: "Sarabun";
    font-size: 1vw;
    padding-left: 0;
    margin: 0px;
    margin-top: -1.5vw;
    margin-left: 3.5vw;
    font-weight: 600;
    color: var(--icons);
}

div.information {
    margin: 1%;
    text-align: left;
    width: 100%;
    padding: 1%;
}

div.subinformation {
    width: 50%;
}

.text {
    vertical-align: middle;
    display: inline-block;
}

h6.texti {
    padding-left: 0;
    margin: 0px;
    margin-top: -1.5vw;
    margin-left: 3.5vw;
}

svg.icon {
    vertical-align: middle;
    display: inline-block;
    stroke: var(--icons);
    width: 1.5vw;
    height: 1.5vw;
    margin-top: 1vw;
    padding: 0;
}

svg.fill_icon {
    fill: var(--icons);
}

div.no_space {
    margin: 0;
    padding: 0;
}

div.tags {
    position: absolute;
    border-radius: 500px;
    color: var(--font-color);
    background-color: var(--background-color);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: -1vw;
    margin-top: -1vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    width: auto;
    border-radius: 500px;
    display: block;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1.5vw;
    top: 1.5vw;
}


p.tag {
    font-size: 1vw;
    font-weight: 600;
    white-space: nowrap;
    overflow: visible;
    display: flex;
}


@media only screen and (max-width: 786px) {
    div.project {
        width: 76vw;
        height: 43vw;
        margin: auto;
        position: relative;
        cursor: pointer;
        background-color: var(--font-color);
        border-radius: 5px;
        z-index: -2;
    }

    a.project:hover {
        transform: translateY(-3px);
    }

    img.project {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
    }

    div.imageproject {
        width: 46%;
        height: 40vw;
        right: 2%;
        top: 0%;
        bottom: 0%;
        margin-top: auto;
        margin-bottom: auto;
        position: absolute;
        z-index: -1;
    }

    h5.projTitle {
        font-family: "Sarabun";
        font-weight: 600;
        font-size: 5vw;
        color: var(--background-color);
    }

    h6 {
        font-family: "Sarabun";
        font-weight: 600;
        font-size: 2vw;
        margin-left: 1vw;
        color: var(--icons);
    }

    p.description {
        font-family: "Sarabun";
        font-size: 2vw;
        padding-left: 0;
        margin-top: -3vw;
        margin-left: 7vw;
        font-weight: 600;
        color: var(--icons);
    }

    div.information {
        margin: 1%;
        text-align: left;
        width: 100%;
        padding: 1%;
    }

    div.subinformation {
        width: 50%;
    }

    .text {
        vertical-align: middle;
        display: inline-block;
    }

    h6.texti {
        padding-left: 0;
        margin: 0px;
        margin-left: 7vw;
        margin-top: -3vw;
    }

    svg.icon {
        vertical-align: middle;
        display: inline-block;
        stroke: var(--icons);
        width: 3vw;
        height: 3vw;
        margin-top: 1vw;
        padding: 0;
    }

    div.tags {
        position: absolute;
        border-radius: 500px;
        color: var(--font-color);
        background-color: var(--background-color);
        margin-right: auto;
        margin-left: auto;
        margin-bottom: -1vw;
        margin-top: -1vw;
        padding-left: 1.5vw;
        padding-right: 1.5vw;
        width: auto;
        border-radius: 500px;
        display: block;
        height: 6vw;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 3vw;
        top: 3vw;
    }
    
    
    p.tag {
        font-size: 2vw;
        font-weight: 600;
        white-space: nowrap;
        overflow: visible;
        display: flex;
    }
}