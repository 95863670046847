@import "../fonts/sarabun.css";

.links {
    color: white;
    width: 30vw;
    height: 10vh;
    right: 0px;
    left: auto;
    position: absolute;
    display: inline-flex;
}

.name {
    color: var(--font-color);
    width: 20vw;
    height: 10vh;
    left: 0px;
    right: auto;
    position: absolute;
    display: inline-flex;
    margin-left: 5vw;
}

svg.logo {
    height: 1.7vw;
    width: auto;
    margin-right: 0.5vw;
    fill: var(--font-color);
}

svg {
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: middle;

}

a {
    color: var(--font-color);
    font-family: "Sarabun";
    font-weight: normal;
    font-size: 1.7vw;
    text-decoration: none;
    margin-left: 0.1%;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding: auto;
}

a:hover {
    font-weight: 600;
}

a.selected {
    color: var(--accent);
    font-weight: 600;
}